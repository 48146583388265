import { useState } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

type FormProps<T> = {
    defaultValues?: any;
    validationSchema: Yup.ObjectSchema<any>;
    onSubmit: (data: T) => void;
  };
  
  export function useFormWithYup<T extends FieldValues>({ defaultValues, validationSchema, onSubmit }: FormProps<T>) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const form = useForm<T>({
      defaultValues,
      resolver: yupResolver(validationSchema),
    });
  
    const handleSubmit = form.handleSubmit((data: T) => {
      setIsSubmitting(true);
      if (onSubmit) {
        onSubmit(data);
      }
    });
  
    return {
      ...form,
      handleSubmit,
      isSubmitting,
    };
  }
  