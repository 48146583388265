import { Navigate, useRoutes } from 'react-router-dom';
import { MainLayout } from 'components/layouts';
import {
  About,
  Contact,
  Home,
  Login,
  Register,
  Reports,
  ManualInput,
  TermCondition,
  NotFound,
  EditReport
} from 'pages';
import { APP_ROUTES } from './routes';
import { UserHelper } from 'utils';

// Private routes can only be accessed by authenticated users
interface PropTypes {
  element: JSX.Element;
}
const PrivateRoute = ({ element }: PropTypes) => {
  const isLoggedIn = UserHelper.isLoggedIn();
  if (!isLoggedIn) {
    return <Navigate to="/" />;
  }

  return element;
};

const PublicRoute = ({ element }: PropTypes) => {
  const isLoggedIn = UserHelper.isLoggedIn();
  if (isLoggedIn) {
    return <Navigate to={APP_ROUTES.DASHBOARD} />;
  }

  return element;
};

export default function Router() {
  let element = useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: APP_ROUTES.HOME, element: <PublicRoute element={<Home />} /> },
        { path: APP_ROUTES.ABOUT, element: <About /> },
        { path: APP_ROUTES.CONTACT, element: <Contact /> },
        {
          path: APP_ROUTES.LOGIN,
          element: <PublicRoute element={<Login />} />,
        },
        {
          path: APP_ROUTES.REGISTER,
          element: <PublicRoute element={<Register />} />,
        },
        { path: APP_ROUTES.MANUAL_INPUT, element: <ManualInput /> },
        { path: APP_ROUTES.TERM_CONDITION, element: <TermCondition /> },
        {
          path: APP_ROUTES.DASHBOARD,
          element: <PrivateRoute element={<Reports />} />,
        },
        {
          path: APP_ROUTES.EDITREPORT,
          element: <PrivateRoute element={<EditReport />} />,
        },
      ],
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]);

  return element;
}
