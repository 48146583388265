import Router from 'router';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Global import
import "assets/scss/app.scss";
import "./i18n";
import { Suspense } from 'react';

function App() {
  return (
    <div className="App" style={{backgroundColor:"#F6F7F8"}}>
      <BrowserRouter>
        <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar={true} theme="colored" newestOnTop={false} closeOnClick
          rtl={false} />
          <Suspense fallback={<div>Loading...</div>}>
          <Router />
          </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
